const links = [
  {
    label: "Github",
    url: "https://github.com/thiagowilliam",
  },

  {
    label: "Linkedin",
    url: "https://www.linkedin.com/in/thiago-william/",
  },
]

export default links
