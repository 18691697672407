const links = [
  {
    label: 'Home',
    url: '/',
    title: 'Home'
  },
  // {
  //   label: 'Sobre mim',
  //   url: '/about',
  //   title: 'Sobre mim'
  // }
]

export default links
